@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?28kg3v');
  src:  url('icomoon.eot?28kg3v#iefix') format('embedded-opentype'),
    url('icomoon.ttf?28kg3v') format('truetype'),
    url('icomoon.woff?28kg3v') format('woff'),
    url('icomoon.svg?28kg3v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="am-icon-"], [class*=" am-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.am-icon-question:before {
  content: "\e920";
}
.am-icon-info:before {
  content: "\e903";
}
.am-icon-parasites:before {
  content: "\e904";
}
.am-icon-beans:before {
  content: "\e905";
}
.am-icon-bees:before {
  content: "\e906";
}
.am-icon-cats:before {
  content: "\e907";
}
.am-icon-grass:before {
  content: "\e908";
}
.am-icon-cockroach:before {
  content: "\e909";
}
.am-icon-dog:before {
  content: "\e90a";
}
.am-icon-eggs:before {
  content: "\e90b";
}
.am-icon-fish:before {
  content: "\e90c";
}
.am-icon-ccd:before {
  content: "\e90d";
}
.am-icon-flowerpot:before {
  content: "\e90e";
}
.am-icon-fruit:before {
  content: "\e90f";
}
.am-icon-grains:before {
  content: "\e910";
}
.am-icon-livestock:before {
  content: "\e911";
}
.am-icon-insect:before {
  content: "\e912";
}
.am-icon-latex:before {
  content: "\e913";
}
.am-icon-spices:before {
  content: "\e914";
}
.am-icon-meat:before {
  content: "\e915";
}
.am-icon-milk:before {
  content: "\e916";
}
.am-icon-mites:before {
  content: "\e917";
}
.am-icon-mushrooms:before {
  content: "\e918";
}
.am-icon-pets:before {
  content: "\e919";
}
.am-icon-peanuts:before {
  content: "\e91a";
}
.am-icon-trees:before {
  content: "\e91b";
}
.am-icon-vegetables:before {
  content: "\e91c";
}
.am-icon-yeast:before {
  content: "\e91d";
}
.am-icon-ant:before {
  content: "\e91e";
}
.am-icon-flower:before {
  content: "\e91f";
}
.am-icon-digestion:before {
  content: "\e900";
}
.am-icon-neurologicalDisorders:before {
  content: "\e901";
}
.am-icon-skin:before {
  content: "\e902";
}
.am-icon-foodId-fruit:before {
  content: "\e921";
}
.am-icon-foodId-beans:before {
  content: "\e922";
}
.am-icon-foodId-ccd:before {
  content: "\e923";
}
.am-icon-foodId-vegetables:before {
  content: "\e924";
}
.am-icon-foodId-eggs:before {
  content: "\e925";
}
.am-icon-foodId-fish:before {
  content: "\e926";
}
.am-icon-foodId-spices:before {
  content: "\e927";
}
.am-icon-foodId-grains:before {
  content: "\e928";
}
.am-icon-foodId-meat:before {
  content: "\e929";
}
.am-icon-foodId-milk:before {
  content: "\e92a";
}
.am-icon-foodId-peanuts:before {
  content: "\e92b";
}
.am-icon-foodId-mushrooms:before {
  content: "\e92c";
}
