@tailwind base;
@tailwind components;
@tailwind utilities;
@import "themes/foodId.css";

html, body {
    min-height: 100%;
    margin: 0;
}

/*#root {*/
/*    min-height: 100%;*/
/*}*/

body {
    --color-primary: #4285f4;
    --color-primary-900: #226AE2;
    --color-blue-600: #E9F1FF;
    --color-blue-500: #3b82f6;
    --color-blue-400: #60a5fa;
    --color-secondary: #70d2b0;
    --color-typography: #ea4335;
}

@layer base {
    a {
        @apply text-blue-900;
        @apply underline;
    }
}

@media only screen and (max-width: 576px) {
    body {
        font-size: 14px;
    }
}

@media only screen {
    .block-shadow {
        -webkit-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
    }
}

.text-dialog li {
    margin-bottom: 1rem;

}


.text-dialog h2 {
    margin-bottom: 1.5rem;
    margin-top: 3.5rem;
}

.nav-link.active {
    color: black;
    border-bottom-color: var(--color-primary);
}

@media only screen and (max-width: 576px) {
    .nav-link.active {
        @apply bg-blue-600;
        @apply border-blue-900;
        @apply text-blue-900;
    }
}

.numbered-list, .numbered-list-top {
    counter-reset: item;
}

.numbered-list-top h2:before {
    content: counters(item, ".") ". ";
    counter-increment: item
}

.numbered-list li:before {
    content: counters(item, ".") ". ";
    counter-increment: item
}

.cs-message-group--incoming .cs-message-group__messages .cs-message .cs-message__content {
    @apply bg-content-300;
}

.cs-message-group--incoming .cs-message-group__messages .cs-message.is-important .cs-message__content {
    @apply bg-blue-600;
}

.cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content {
    @apply bg-blue-800;
    color: #fff;
}

.cs-message-group--custom .cs-message-group__messages .cs-message .cs-message__content {
    background: #fff;
    padding: 0;
}

.cs-message-input__content-editor,
.cs-message__content {
    @apply font-sans;
    font-size: 14px;
}

.cs-message-group--custom {
    margin-top: 1rem;
}

.cs-message-list__scroll-wrapper {
    padding: 0 1rem;
}

.cs-message-input__content-editor-wrapper {
    @apply border-content-500 border;
    background-color: #fff;
}

.cs-message-input__content-editor {
    background-color: #fff;
}

.cs-button--send {
    @apply text-blue-800;
}

.cs-chat-container .cs-message-input {
    @apply border-0;
    padding-bottom: .8em;
}

.cs-main-container .cs-chat-container {
    @apply border-0;
}

.cs-main-container {
    @apply border-0;
}

.ck-editor ul, .cs-message ul {
    @apply !list-disc !ml-4 !pl-4 mb-3;
}

.ck-editor ol, .cs-message ol {
    @apply !list-decimal !ml-4 !pl-4 mb-3;
}

.ck-editor p, .cs-message p {
    @apply mb-3;
}

.react-datepicker-popper {
    z-index: 10 !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    margin-left: -25px !important;
}

.react-datepicker__header select{
    font-size: 13px;
    line-height: 1.3;
    margin-top: 7px;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select {
    margin: 0 10px !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.border-red-500,
.react-select__control {
    border-color: red !important;
}

.dzu-inputLabel {
    margin-left: auto;
    margin-right: auto;
}

.dzu-inputLabelWithFiles {
    margin-left: auto !important;
    margin-right: auto;
    margin-top: 0 !important;
}

.text-page table {
    max-width: 100% !important;
    width: 100% !important;
    font-size: 16px;
    line-height: 1.3;
    margin-top: 20px;

}

.text-page >ol {
    margin-bottom: 30px;
    margin-left: 20px;
}

.text-page li {
    margin-bottom: 15px;
}

.text-page li ol {
    white-space: normal !important;
    margin-left: 20px;
    padding-left: 20px;
}


.text-page p {
    margin-bottom: 20px;

}
