@font-face {
    font-family: 'Atkinson-Hyperlegible';
    font-weight: 400;
    font-style: normal;
    src: local('Atkinson-HyperLegible'),
    url('ttf/Atkinson-Hyperlegible-Regular-102.ttf') format('truetype'),
    url('woff/Atkinson-Hyperlegible-Regular-102.woff') format('woff'),
    url('woff2/Atkinson-Hyperlegible-Regular-102a.woff2') format('woff2'),
    url('svg/Atkinson-Hyperlegible-Regular-102.svg') format('svg');
}

@font-face {
    font-family: 'Atkinson-Hyperlegible';
    font-weight: 700;
    font-style: normal;
    src: local('Atkinson-HyperLegible'),
    url('ttf/Atkinson-Hyperlegible-Bold-102.ttf') format('truetype'),
    url('woff/Atkinson-Hyperlegible-Bold-102.woff') format('woff'),
    url('woff2/Atkinson-Hyperlegible-Bold-102a.woff2') format('woff2'),
    url('svg/Atkinson-Hyperlegible-Bold-102.svg') format('svg');
}
